


    /**
     * initMap
     *
     * Renders a Google Map onto the selected jQuery element
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @return  object The map instance.
     */
    function initMap() {
        const maps = document.querySelectorAll('.map-container');
        maps.forEach(m => _initMap($(m)));
    }

    function _initMap(mapContainer) {
        // Find marker elements within map.
        var $markers = mapContainer.find('.marker');

        // Create gerenic map.
        var mapArgs = {
            zoom: mapContainer.data('zoom') || 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(mapContainer[0], mapArgs);

        // Add markers.
        map.markers = [];
        $markers.each(function () {
            initMarker($(this), map);
        });

        // Center map based on markers.
        centerMap(map);

        // Return map instance.
        return map;
    }

    /**
     * initMarker
     *
     * Creates a marker for the given jQuery element and map.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @param   object The map instance.
     * @return  object The marker instance.
     */
    function initMarker($marker, map) {

        // Get position from marker.
        const lat = $marker.data('lat');
        const lng = $marker.data('lng');
        const placeId = $marker.data('placeId');
        const latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };

        // Create marker instance.
        const marker = new google.maps.Marker({
            position: latLng,
            map: map
        });

        // Append to reference for later use.
        map.markers.push(marker);

        // Create info window.
        const infowindow = new google.maps.InfoWindow({
            content: ''
        });

        // Show info window when marker is clicked.
        google.maps.event.addListener(marker, 'click', function () {
            //infowindow.open(map, marker);
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${placeId}`, '_blank');
        });
    }

    /**
     * centerMap
     *
     * Centers the map showing all markers in view.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   object The map instance.
     * @return  void
     */
    function centerMap(map) {

        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function (marker) {
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });

        // Case: Single marker.
        if (map.markers.length == 1) {
            map.setCenter(bounds.getCenter());

            // Case: Multiple markers.
        } else {
            map.fitBounds(bounds);
        }
    }



    window.initMap = initMap;