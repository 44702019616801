const cards = document.querySelectorAll('.post-card');

// Function to animate elements with a stagger effect
function animateCards() {
    cards.forEach((card, index) => {
        // Add delay to each card based on its index
        setTimeout(() => {
            card.style.opacity = 1;
            card.style.transform = 'translateY(-15px)';
        }, index * 150); // Change 200 to adjust the stagger duration
    });
}

// Call the function to start the animation
//animateCards();