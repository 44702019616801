const $reactions = $('.post-reactions');
const $likeButton = $reactions.find('.like');

export function likePost($button) {
    let $current_post = $button.parents('article');

    let data = new FormData();
    data.append('action', 'like_post');
    data.append('post_id', $button.data('postId'));
    data.append('status', $button.hasClass('liked') ? -1 : 1);
    
    $.ajax({
        url: feltpost_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {

        },
        complete: function (data) {
            const results = data.responseJSON;
            const likes = results.likes;
            let html = '';

            if (likes.length > 1) {
                html = likes.slice(0, -1).join(', ') + ' og ' + likes.slice(-1).join('');
            } else {
                html = likes.join('');
            }

            $current_post.find('.likes .users span').html(html);
            $current_post.find('.like-count span').html(likes.length);

            if (results.likes.length > 0) {
                $current_post.find('.post-reactions').addClass('has-likes');
            } else {
                $current_post.find('.post-reactions').removeClass('has-likes');
            }

            if($button.hasClass('liked')) {
                $button.removeClass('liked');
                $button.find('span').text('Lik');
            }
            else {
                $button.addClass('liked');
                $button.find('span').text('Liker');
            }
        }
    });

}
$likeButton.click(function(e) {
    e.preventDefault();
    likePost($(this));
})