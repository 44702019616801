//import './_body-view-height';
import './_body-view-width';
import './_header-height';
import './header';
import './_search';
import './_lazy-image.js';
//import './_slideshow.js';

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if(skipHeader != undefined) {
    skipHeader.addEventListener('focusin', function (e) {
        this.classList.add('focused');  
    });
    skipHeader.addEventListener('focusout', function (e) {
        this.classList.remove('focused');  
    });
}

import './_animations.js';
import './_employee-filter.js';
import './_map.js';
import './_post-reactions.js';
import './_comment-reactions.js';
import './_sharing-wall-page.js';
import './_block-posts-with-featured.js';