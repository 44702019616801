
const filter = document.getElementById('department-filter') || document.getElementById('search-input');

if (filter !== null ){


document.getElementById('department-filter').addEventListener('change', function() {
    var selectedClass = 'employee-'+this.value;
    var listItems = document.querySelectorAll('#filter-target li');

    listItems.forEach(function(item) {
        if (selectedClass === 'all') {
            item.style.display = 'flex';
        } else if (item.classList.contains(selectedClass)) {
            item.style.display = 'flex';
        } else {
            item.style.display = 'none';
        }
    });
});

    document.getElementById('search-input').addEventListener('input', function() {
        var searchText = this.value.toLowerCase();
        var listItems = document.querySelectorAll('#filter-target li');
        console.log(searchText)

        listItems.forEach(function(item) {
            var employeeName = item.querySelector('.employee-name').textContent.toLowerCase();
            if (searchText === '' || employeeName.includes(searchText)) {
                item.style.display = 'flex';
            } else {
                item.style.display = 'none';
            }
        });
    });
    }
