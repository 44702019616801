/*
 * Calculate header height.

 * In CSS, use the following to get the height:
 *   height: var(--header-height);
 */
const setupHeaderHeight = () => {
    let header = document.querySelector('header');
    if(header) {
        let hh = document.querySelector('header').offsetHeight + 20;
        document.documentElement.style.setProperty('--header-height', `${hh}px`);
    }
}
setupHeaderHeight();
window.addEventListener('resize', setupHeaderHeight);
